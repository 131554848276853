// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-an-und-verkauf-js": () => import("./../../../src/pages/an-und-verkauf.js" /* webpackChunkName: "component---src-pages-an-und-verkauf-js" */),
  "component---src-pages-haushaltsaufloesungen-js": () => import("./../../../src/pages/haushaltsaufloesungen.js" /* webpackChunkName: "component---src-pages-haushaltsaufloesungen-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-templates-image-template-js": () => import("./../../../src/templates/imageTemplate.js" /* webpackChunkName: "component---src-templates-image-template-js" */),
  "component---src-templates-legal-template-js": () => import("./../../../src/templates/legalTemplate.js" /* webpackChunkName: "component---src-templates-legal-template-js" */),
  "component---src-templates-product-template-js": () => import("./../../../src/templates/productTemplate.js" /* webpackChunkName: "component---src-templates-product-template-js" */)
}

